import React, { useState, useEffect } from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';
import WorkoutSearchDropdown from './WorkoutSearchDropdown';
import { axiosInstance } from '../axiosConfig';

const WorkoutBuilder = ({ onSave, onCancel, clientId, template }) => {
  const [workoutPlan, setWorkoutPlan] = useState({
    title: '',
    description: '',
    sessions: []
  });

  const [activeDropdown, setActiveDropdown] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (template) {
      setWorkoutPlan({
        title: template.title || '',
        description: template.description || '',
        sessions: template.sessions?.map(session => ({
          ...session,
          movements: session.movements?.map(movement => ({
            ...movement,
            prescribed_sets: movement.prescribed_sets || 0,
            prescribed_reps: movement.prescribed_reps || '',
            prescribed_rest: movement.prescribed_rest || '',
            notes: movement.notes || ''
          })) || []
        })) || []
      });
    }
  }, [template]);

  const addSession = () => {
    setWorkoutPlan(prev => ({
      ...prev,
      sessions: [
        ...prev.sessions,
        {
          day: prev.sessions.length + 1,
          coaches_intro_note: '',
          approx_time: '',
          difficulty_level: '',
          tempo: '',
          best_practice: '',
          image_url: '',
          movements: []
        }
      ]
    }));
  };

  const addMovement = (sessionIndex) => {
    setWorkoutPlan(prev => ({
      ...prev,
      sessions: prev.sessions.map((session, index) => 
        index === sessionIndex
          ? {
              ...session,
              movements: [
                ...session.movements,
                { title: '', prescribed_sets: 0, prescribed_reps: '', prescribed_rest: '', notes: '' }
              ]
            }
          : session
      )
    }));
  };

  const handleWorkoutPlanChange = (e) => {
    const { name, value } = e.target;
    setWorkoutPlan(prev => ({ ...prev, [name]: value }));
  };

  const handleSessionChange = (sessionIndex, e) => {
    const { name, value } = e.target;
    setWorkoutPlan(prev => ({
      ...prev,
      sessions: prev.sessions.map((session, index) => 
        index === sessionIndex ? { ...session, [name]: value } : session
      )
    }));
  };

  const handleMovementChange = (sessionIndex, movementIndex, e) => {
    const { name, value } = e.target;
    setWorkoutPlan(prev => ({
      ...prev,
      sessions: prev.sessions.map((session, sIndex) => 
        sIndex === sessionIndex
          ? {
              ...session,
              movements: session.movements.map((movement, mIndex) =>
                mIndex === movementIndex ? { ...movement, [name]: value } : movement
              )
            }
          : session
      )
    }));
  };

  const handleMovementTitleClick = (sessionIndex, movementIndex) => {
    setActiveDropdown({ sessionIndex, movementIndex });
  };

  const handleWorkoutSelect = (sessionIndex, movementIndex, selectedWorkout) => {
    handleMovementChange(sessionIndex, movementIndex, {
      target: { name: 'title', value: selectedWorkout.title }
    });
    setActiveDropdown(null);
  };

  const closeDropdown = () => {
    setActiveDropdown(null);
  };

  const handleSaveNewPlan = async () => {
    try {
      const response = await axiosInstance.post('/cd-workout/', {
        client_id: clientId,
        plan: {
          ...workoutPlan,
          title: workoutPlan.title || "New Workout Plan",  
          description: workoutPlan.description || "", 
          sessions: workoutPlan.sessions || []  
        }
      });
      onSave(response.data);
    } catch (err) {
      console.error('Error creating new workout plan:', err.response?.data || err.message);
      setError(err.response?.data?.error || 'Failed to create new workout plan. Please try again.');
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-lg max-w-4xl mx-auto">
      <h2 className="text-2xl font-bold mb-6">
        {template ? 'Create Workout Plan from Template' : 'Create Workout Plan'}
      </h2>
      
      {error && <div className="text-red-500 mb-4">{error}</div>}

      <div className="space-y-4 mb-8">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Title</label>
          <input
            type="text"
            name="title"
            value={workoutPlan.title}
            onChange={handleWorkoutPlanChange}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Description</label>
          <textarea
            name="description"
            value={workoutPlan.description}
            onChange={handleWorkoutPlanChange}
            className="w-full p-2 border border-gray-300 rounded-md"
            rows="3"
          />
        </div>
      </div>

      {workoutPlan.sessions.map((session, sessionIndex) => (
        <div key={sessionIndex} className="mb-8 border border-gray-200 rounded-md p-4">
          <h3 className="text-lg font-semibold mb-4">Session {session.day}</h3>
          <div className="space-y-4 mb-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Coach's Intro Note</label>
              <textarea
                name="coaches_intro_note"
                value={session.coaches_intro_note}
                onChange={(e) => handleSessionChange(sessionIndex, e)}
                className="w-full p-2 border border-gray-300 rounded-md"
                rows="2"
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Approx. Time</label>
                <input
                  type="text"
                  name="approx_time"
                  value={session.approx_time}
                  onChange={(e) => handleSessionChange(sessionIndex, e)}
                  className="w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Difficulty Level</label>
                <select
                  name="difficulty_level"
                  value={session.difficulty_level}
                  onChange={(e) => handleSessionChange(sessionIndex, e)}
                  className="w-full p-2 border border-gray-300 rounded-md"
                >
                  <option value="">Select difficulty</option>
                  <option value="Beginner">Beginner</option>
                  <option value="Intermediate">Intermediate</option>
                  <option value="Advanced">Advanced</option>
                </select>
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Tempo</label>
              <input
                type="text"
                name="tempo"
                value={session.tempo}
                onChange={(e) => handleSessionChange(sessionIndex, e)}
                className="w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Best Practice</label>
              <textarea
                name="best_practice"
                value={session.best_practice}
                onChange={(e) => handleSessionChange(sessionIndex, e)}
                className="w-full p-2 border border-gray-300 rounded-md"
                rows="2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Image URL</label>
              <input
                type="text"
                name="image_url"
                value={session.image_url}
                onChange={(e) => handleSessionChange(sessionIndex, e)}
                className="w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
          </div>

          <h4 className="text-md font-semibold mb-2">Movements</h4>
          {session.movements.map((movement, movementIndex) => (
            <div key={movementIndex} className="mb-4 p-4 border border-gray-200 rounded-md">
              <div className="grid grid-cols-2 gap-4 mb-2">
                <div className="relative">
                  <label className="block text-sm font-medium text-gray-700 mb-1">Title</label>
                  <input
                    type="text"
                    name="title"
                    value={movement.title}
                    onClick={() => handleMovementTitleClick(sessionIndex, movementIndex)}
                    className="w-full p-2 border border-gray-300 rounded-md cursor-pointer"
                    readOnly
                  />
                  {activeDropdown && 
                   activeDropdown.sessionIndex === sessionIndex && 
                   activeDropdown.movementIndex === movementIndex && (
                    <WorkoutSearchDropdown
                      onSelect={(workout) => handleWorkoutSelect(sessionIndex, movementIndex, workout)}
                      onClose={closeDropdown}
                    />
                  )}
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Prescribed Sets</label>
                  <input
                    type="number"
                    name="prescribed_sets"
                    value={movement.prescribed_sets}
                    onChange={(e) => handleMovementChange(sessionIndex, movementIndex, e)}
                    className="w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4 mb-2">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Prescribed Reps</label>
                  <input
                    type="text"
                    name="prescribed_reps"
                    value={movement.prescribed_reps}
                    onChange={(e) => handleMovementChange(sessionIndex, movementIndex, e)}
                    className="w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Prescribed Rest</label>
                  <input
                    type="text"
                    name="prescribed_rest"
                    value={movement.prescribed_rest}
                    onChange={(e) => handleMovementChange(sessionIndex, movementIndex, e)}
                    className="w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Notes</label>
                <textarea
                  name="notes"
                  value={movement.notes}
                  onChange={(e) => handleMovementChange(sessionIndex, movementIndex, e)}
                  className="w-full p-2 border border-gray-300 rounded-md"
                  rows="2"
                />
              </div>
            </div>
          ))}
          <button
            onClick={() => addMovement(sessionIndex)}
            className="flex items-center text-blue-600 font-medium"
          >
            <PlusIcon className="w-5 h-5 mr-1" /> Add Movement
          </button>
        </div>
      ))}

      <button
        onClick={addSession}
        className="flex items-center text-blue-600 font-medium mb-6"
      >
        <PlusIcon className="w-5 h-5 mr-1" /> Add Session
      </button>

      <div className="flex justify-end space-x-2">
        <button onClick={onCancel} className="px-4 py-2 border border-gray-300 rounded-md">Cancel</button>
        <button onClick={handleSaveNewPlan} className="bg-blue-600 text-white px-4 py-2 rounded-md">Create Workout Plan</button>
      </div>
    </div>
  );
};

export default WorkoutBuilder;