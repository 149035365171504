import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, TextField, IconButton } from '@mui/material';
import { Rnd } from 'react-rnd';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import ReactLoading from 'react-loading';
import { toggleCheckInPanel, setCurrentCheckIn, setCheckInResponses } from '../redux/slices/panelSlice';
import { fetchCheckIns, fetchCheckInResponses, submitCheckInResponse } from '../api';
import TextareaAutosize from 'react-textarea-autosize';

const ImageViewer = ({ image, onClose, index }) => {
  return (
    <Rnd
      default={{
        x: 50 + index * 30,
        y: 50 + index * 30,
        width: 600,
        height: 600,
      }}
      minWidth={300}
      minHeight={300}
      bounds="window"
    >
      <div className="bg-white shadow-lg rounded-lg overflow-hidden h-full flex flex-col">
        <div className="flex justify-end p-2 border-b">
          <IconButton onClick={onClose}><CloseIcon /></IconButton>
        </div>
        <div className="flex-grow flex items-center justify-center p-4 overflow-hidden">
          <img 
            src={`data:image/jpeg;base64,${image}`} 
            alt="Enlarged view" 
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain',
            }}
          />
        </div>
      </div>
    </Rnd>
  );
};

const CheckInPanel = ({ clientId }) => {
  const dispatch = useDispatch();
  const { checkInPanelOpen, currentCheckIn, checkInResponses } = useSelector(state => state.panels);
  const [response, setResponse] = useState('');
  const [checkIns, setCheckIns] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [openPanels, setOpenPanels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openImages, setOpenImages] = useState([]);

  useEffect(() => {
    if (checkInPanelOpen) {
      setIsLoading(true);
      Promise.all([
        fetchCheckIns(clientId),
        fetchCheckInResponses(clientId)
      ]).then(([checkInsData, responsesData]) => {
        const sortedCheckIns = checkInsData.sort((a, b) => new Date(b.date) - new Date(a.date));
        setCheckIns(sortedCheckIns);
        if (sortedCheckIns.length > 0) {
          dispatch(setCurrentCheckIn(sortedCheckIns[0]));
        }
        dispatch(setCheckInResponses(responsesData));
      }).catch(error => {
        console.error('Error fetching check-in data:', error);
      }).finally(() => {
        setIsLoading(false);
      });
    }
  }, [checkInPanelOpen, clientId, dispatch]);

  const handleSubmitResponse = async () => {
    if (response.trim() && currentCheckIn) {
      try {
        const newResponse = await submitCheckInResponse(currentCheckIn.id, response);
        dispatch(setCheckInResponses([newResponse, ...checkInResponses]));
        setResponse('');
      } catch (error) {
        console.error('Error submitting check-in response:', error);
      }
    }
  };

  const navigateCheckIn = (direction) => {
    const newIndex = direction === 'next' 
      ? Math.min(currentIndex + 1, checkIns.length - 1)
      : Math.max(currentIndex - 1, 0);
    setCurrentIndex(newIndex);
    dispatch(setCurrentCheckIn(checkIns[newIndex]));
  };

  const openNewTab = () => {
    setOpenPanels([...openPanels, { id: Date.now(), checkIn: currentCheckIn }]);
  };

  const closeTab = (id) => {
    setOpenPanels(openPanels.filter(panel => panel.id !== id));
  };

  const handleImageClick = (image) => {
    setOpenImages([...openImages, image]);
  };

  const handleCloseImage = (index) => {
    setOpenImages(openImages.filter((_, i) => i !== index));
  };

  const CheckInContent = ({ checkIn }) => (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden max-w-2xl mx-auto">
      <h3 className="text-2xl font-bold text-gray-800 bg-gray-100 p-4">Check-In Details</h3>
      
      {checkIn.photos && (
        <div className="flex justify-center gap-2 p-4 bg-gray-50">
          {checkIn.photos.front && (
            <img 
              src={`data:image/jpeg;base64,${checkIn.photos.front}`} 
              alt="Front" 
              className="w-1/3 h-40 object-cover rounded-lg cursor-pointer" 
              onClick={() => handleImageClick(checkIn.photos.front)}
            />
          )}
          {checkIn.photos.side && (
            <img 
              src={`data:image/jpeg;base64,${checkIn.photos.side}`} 
              alt="Side" 
              className="w-1/3 h-40 object-cover rounded-lg cursor-pointer" 
              onClick={() => handleImageClick(checkIn.photos.side)}
            />
          )}
          {checkIn.photos.back && (
            <img 
              src={`data:image/jpeg;base64,${checkIn.photos.back}`} 
              alt="Back" 
              className="w-1/3 h-40 object-cover rounded-lg cursor-pointer" 
              onClick={() => handleImageClick(checkIn.photos.back)}
            />
          )}
        </div>
      )}
      
      <div className="p-6 space-y-4">
        <div className="grid grid-cols-2 gap-4">
          <div className="text-sm"><span className="font-semibold text-gray-600">Date:</span> <span className="text-gray-800">{new Date(checkIn.date).toLocaleString()}</span></div>
          <div className="text-sm"><span className="font-semibold text-gray-600">Weight:</span> <span className="text-gray-800">{checkIn.average_weekly_weight} lbs</span></div>
          <div className="text-sm"><span className="font-semibold text-gray-600">Sleep Quality:</span> <span className="text-gray-800">{checkIn.sleep_quality}</span></div>
          <div className="text-sm"><span className="font-semibold text-gray-600">Avg Hours Slept:</span> <span className="text-gray-800">{checkIn.average_hours_slept}</span></div>
          <div className="text-sm"><span className="font-semibold text-gray-600">Avg Water Intake:</span> <span className="text-gray-800">{checkIn.average_water_intake} {checkIn.water_intake_unit}</span></div>
          <div className="text-sm"><span className="font-semibold text-gray-600">Energy Level:</span> <span className="text-gray-800">{checkIn.energy_level}/5</span></div>
          <div className="text-sm"><span className="font-semibold text-gray-600">Hunger Level:</span> <span className="text-gray-800">{checkIn.hunger_level}</span></div>
          <div className="text-sm"><span className="font-semibold text-gray-600">Meal Plan Usage:</span> <span className="text-gray-800">{checkIn.meal_plan_usage}/5</span></div>
          <div className="text-sm"><span className="font-semibold text-gray-600">Workout Plan Usage:</span> <span className="text-gray-800">{checkIn.workout_plan_usage}/5</span></div>
        </div>
        
        <div>
          <h4 className="text-lg font-semibold text-gray-700 mb-2">Body Measurements</h4>
          <div className="grid grid-cols-3 gap-2 text-sm">
            <div><span className="font-medium text-gray-600">Arm:</span> <span className="text-gray-800">{checkIn.body_measurement_arm} in</span></div>
            <div><span className="font-medium text-gray-600">Chest:</span> <span className="text-gray-800">{checkIn.body_measurement_chest} in</span></div>
            <div><span className="font-medium text-gray-600">Leg:</span> <span className="text-gray-800">{checkIn.body_measurement_leg} in</span></div>
            <div><span className="font-medium text-gray-600">Stomach:</span> <span className="text-gray-800">{checkIn.body_measurement_stomach} in</span></div>
            <div><span className="font-medium text-gray-600">Waist:</span> <span className="text-gray-800">{checkIn.body_measurement_waist} in</span></div>
          </div>
        </div>
        
        <div>
          <h4 className="text-lg font-semibold text-gray-700 mb-2">Notes</h4>
          <div className="space-y-2 text-sm">
            <p><span className="font-medium text-gray-600">Meal Plan:</span> <span className="text-gray-800">{checkIn.meal_plan_notes}</span></p>
            <p><span className="font-medium text-gray-600">Workout Plan:</span> <span className="text-gray-800">{checkIn.workout_plan_notes}</span></p>
            <p><span className="font-medium text-gray-600">Wins:</span> <span className="text-gray-800">{checkIn.wins}</span></p>
            <p><span className="font-medium text-gray-600">Troubles:</span> <span className="text-gray-800">{checkIn.troubles}</span></p>
            <p><span className="font-medium text-gray-600">Troubleshoot:</span> <span className="text-gray-800">{checkIn.trouble_shoot}</span></p>
          </div>
        </div>
      </div>
    </div>
  );

  if (!checkInPanelOpen) return null;

  return (
    <>
      <Rnd
        default={{
          x: 0,
          y: 0,
          width: 700,
          height: 900,
        }}
        minWidth={400}
        minHeight={500}
      >
        <div className="bg-white shadow-lg rounded-lg overflow-hidden h-full flex flex-col">
          <div className="flex justify-between items-center p-4 border-b">
            <h2 className="text-xl font-bold">Client Check-Ins</h2>
            <div>
              <IconButton onClick={openNewTab}><AddIcon /></IconButton>
              <IconButton onClick={() => dispatch(toggleCheckInPanel())}><CloseIcon /></IconButton>
            </div>
          </div>
          <div className="flex-grow overflow-y-auto p-4">
            {isLoading ? (
              <div className="flex items-center justify-center h-full">
                <ReactLoading type="balls" color="#50c878" height={50} width={50} />
              </div>
            ) : currentCheckIn ? (
              <>
                <CheckInContent checkIn={currentCheckIn} />
                <div className="flex justify-between mb-4">
                  <IconButton onClick={() => navigateCheckIn('prev')} disabled={currentIndex === 0}>
                    <ArrowBackIosIcon />
                  </IconButton>
                  <IconButton onClick={() => navigateCheckIn('next')} disabled={currentIndex === checkIns.length - 1}>
                    <ArrowForwardIosIcon />
                  </IconButton>
                </div>
                <div className="mb-4">
                  <h3 className="text-lg font-semibold mb-2">Responses</h3>
                  {checkInResponses.map(response => (
                    <div key={response.id} className="mb-2 p-2 bg-gray-100 rounded">
                      <p><strong>{response.coach.username}:</strong> {response.response}</p>
                      <small>{new Date(response.timestamp).toLocaleString()}</small>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <p className="text-center mt-10">No check-ins available</p>
            )}
          </div>
          <div className="p-4 border-t">
            <TextareaAutosize
              minRows={2}
              value={response}
              onChange={(e) => setResponse(e.target.value)}
              placeholder="Enter your response..."
              style={{
                width: '100%',
                padding: '8px',
                marginBottom: '8px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                fontFamily: 'inherit',
                fontSize: 'inherit',
                resize: 'none',
              }}
            />
            <Button 
              variant="contained" 
              color="primary"
              onClick={handleSubmitResponse}
              fullWidth
            >
              Submit Response
            </Button>
          </div>
        </div>
      </Rnd>
      {openPanels.map(panel => (
        <Rnd
          key={panel.id}
          default={{
            x: 20 + openPanels.indexOf(panel) * 20,
            y: 20 + openPanels.indexOf(panel) * 20,
            width: 400,
            height: 500,
          }}
          minWidth={300}
          minHeight={400}
        >
          <div className="bg-white shadow-lg rounded-lg overflow-hidden h-full flex flex-col">
            <div className="flex justify-between items-center p-2 border-b">
              <h3 className="text-lg font-semibold">Check-In Details</h3>
              <IconButton onClick={() => closeTab(panel.id)}><CloseIcon /></IconButton>
            </div>
            <div className="flex-grow overflow-y-auto p-4">
              <CheckInContent checkIn={panel.checkIn} />
            </div>
          </div>
        </Rnd>
      ))}
      {openImages.map((image, index) => (
        <ImageViewer
          key={index}
          image={image}
          onClose={() => handleCloseImage(index)}
          index={index}
        />
      ))}
    </>
  );
};

export default CheckInPanel;