import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, TextField, List, Typography, Box, IconButton } from '@mui/material';
import { Rnd } from 'react-rnd';
import { toggleMessagesPanel, setMessages } from '../redux/slices/panelSlice';
import ReactLoading from 'react-loading';
import { fetchMessages, sendMessage } from '../api';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';

const ImageViewer = ({ image, onClose, index }) => {
  return (
    <Rnd
      default={{
        x: 50 + index * 30,
        y: 50 + index * 30,
        width: 600,
        height: 600,
      }}
      minWidth={300}
      minHeight={300}
      bounds="window"
    >
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          bgcolor: 'background.paper',
          boxShadow: 3,
          borderRadius: 2,
          overflow: 'hidden',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2, overflow: 'hidden' }}>
          <img 
            src={`data:image/jpeg;base64,${image}`} 
            alt="Enlarged view" 
            style={{ 
              maxWidth: '100%', 
              maxHeight: '100%', 
              objectFit: 'contain' 
            }} 
          />
        </Box>
      </Box>
    </Rnd>
  );
};

const MessagesPanel = ({ clientId, clientName, isOpen, onToggle }) => {
  const dispatch = useDispatch();
  const { messages } = useSelector(state => state.panels);
  const [newMessage, setNewMessage] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const currentUser = useSelector(state => state.auth.user);
  const messagesEndRef = useRef(null);
  const fileInputRef = useRef(null);
  const [openImages, setOpenImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      fetchMessages(clientId)
        .then(data => {
          dispatch(setMessages(data));
        })
        .catch(error => {
          console.error('Error fetching messages:', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [isOpen, clientId, dispatch]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleSendMessage = async () => {
    try {
      const text = newMessage.trim() || '';
      const sentMessage = await sendMessage(clientId, text, currentUser.id, selectedImage);
      dispatch(setMessages([...messages, sentMessage]));
      setNewMessage('');
      setSelectedImage(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleImageSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
    }
  };

  const handleImageClick = (image) => {
    setOpenImages([...openImages, image]);
  };

  const handleCloseImage = (index) => {
    setOpenImages(openImages.filter((_, i) => i !== index));
  };

  const renderMessage = (message) => {
    const isCurrentUser = message.sender === currentUser.id;
    const senderName = isCurrentUser ? 'You' : clientName;
    
    return (
      <Box
        key={message.id}
        sx={{
          display: 'flex',
          justifyContent: isCurrentUser ? 'flex-end' : 'flex-start',
          mb: 2,
        }}
      >
        <Box
          sx={{
            maxWidth: '80%',
            backgroundColor: isCurrentUser ? 'primary.light' : 'grey.100',
            borderRadius: 2,
            px: 2,
            py: 1,
          }}
        >
          <Typography variant="subtitle2" color={isCurrentUser ? 'primary.dark' : 'text.primary'}>
            {senderName}
          </Typography>
          {message.text && (
            <Typography variant="body2" sx={{ mt: 0.5 }}>
              {message.text}
            </Typography>
          )}
          {message.image && (
            <Box sx={{ mt: 1, cursor: 'pointer' }} onClick={() => handleImageClick(message.image)}>
              <img 
                src={`data:image/jpeg;base64,${message.image}`} 
                alt="Message attachment" 
                style={{ maxWidth: '100%', maxHeight: '200px', objectFit: 'contain' }} 
              />
            </Box>
          )}
          <Typography variant="caption" color="text.secondary" sx={{ mt: 0.5, display: 'block' }}>
            {new Date(message.timestamp).toLocaleString()}
          </Typography>
        </Box>
      </Box>
    );
  };

  if (!isOpen) return null;

  const panelWidth = 400;
  const panelHeight = 500;

  return (
    <>
      <Rnd
        default={{
          x: window.innerWidth / 2 - panelWidth / 2,
          y: window.innerHeight / 2 - panelHeight / 2,
          width: panelWidth,
          height: panelHeight,
        }}
        minWidth={300}
        minHeight={400}
        bounds="window"
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            bgcolor: 'background.paper',
            boxShadow: 3,
            borderRadius: 2,
            overflow: 'hidden',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2, borderBottom: 1, borderColor: 'divider' }}>
            <Typography variant="h6">Messages with {clientName}</Typography>
            <Button onClick={onToggle}>Close</Button>
          </Box>
          <List sx={{ flexGrow: 1, overflowY: 'auto', p: 2 }}>
            {isLoading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <ReactLoading type="balls" color="#50c878" height={50} width={50} />
              </Box>
            ) : (
              <>
                {messages.map(renderMessage)}
                <div ref={messagesEndRef} />
              </>
            )}
          </List>
          <Box sx={{ p: 2, borderTop: 1, borderColor: 'divider' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <TextField
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder="Type a message..."
                fullWidth
                variant="outlined"
                size="small"
              />
              <input
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                ref={fileInputRef}
                onChange={handleImageSelect}
              />
              <IconButton onClick={() => fileInputRef.current.click()} size="small">
                <AttachFileIcon />
              </IconButton>
            </Box>
            {selectedImage && (
              <Typography variant="caption" sx={{ mb: 1, display: 'block' }}>
                Selected image: {selectedImage.name}
              </Typography>
            )}
            <Button 
              variant="contained" 
              color="primary"
              onClick={handleSendMessage}
              fullWidth
              disabled={!newMessage.trim() && !selectedImage}
            >
              Send
            </Button>
          </Box>
        </Box>
      </Rnd>
      {openImages.map((image, index) => (
        <ImageViewer
          key={index}
          image={image}
          onClose={() => handleCloseImage(index)}
          index={index}
        />
      ))}
    </>
  );
};

export default MessagesPanel;