import React, { useState } from 'react';
import { Rnd } from 'react-rnd';
import WorkoutSearchDropdown from './WorkoutSearchDropdown';
import { axiosInstance } from '../axiosConfig';
import { ChevronDown, ChevronUp, X, ArrowUp, ArrowDown } from 'lucide-react';

const EditWorkoutPlanPopup = ({ plan, onClose, onSave, clientId })  => {
  const [editedPlan, setEditedPlan] = useState(plan);
  const [activeSearchDropdown, setActiveSearchDropdown] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);
  const [expandedSessions, setExpandedSessions] = useState({});


  const handleInputChange = (e, field) => {
    setEditedPlan({ ...editedPlan, [field]: e.target.value });
  };

  const handleSessionChange = (sessionIndex, field, value) => {
    const newSessions = [...editedPlan.sessions];
    newSessions[sessionIndex] = { ...newSessions[sessionIndex], [field]: value };
    setEditedPlan({ ...editedPlan, sessions: newSessions });
  };

  const handleMovementChange = (sessionIndex, movementIndex, field, value) => {
    const newSessions = [...editedPlan.sessions];
    newSessions[sessionIndex].movements[movementIndex] = { 
      ...newSessions[sessionIndex].movements[movementIndex], 
      [field]: value 
    };
    setEditedPlan({ ...editedPlan, sessions: newSessions });
  };

  const handleDeleteMovement = (sessionIndex, movementIndex) => {
    const newSessions = [...editedPlan.sessions];
    newSessions[sessionIndex].movements.splice(movementIndex, 1);
    setEditedPlan({ ...editedPlan, sessions: newSessions });
  };


  const handleWorkoutSelect = (sessionIndex, movementIndex, selectedWorkout) => {
    const newSessions = [...editedPlan.sessions];
    newSessions[sessionIndex].movements[movementIndex] = {
      ...newSessions[sessionIndex].movements[movementIndex],
      title: selectedWorkout.title,
      id: selectedWorkout.id
    };
    setEditedPlan({ ...editedPlan, sessions: newSessions });
    setActiveSearchDropdown(null);
  };

  const handleAddMovement = (sessionIndex) => {
    const newSessions = [...editedPlan.sessions];
    newSessions[sessionIndex].movements.push({
      id: Date.now(), // Temporary ID
      title: 'New Movement',
      prescribed_sets: 1,
      prescribed_reps: 1,
      prescribed_rest: ''
    });
    setEditedPlan({ ...editedPlan, sessions: newSessions });
  };

  const handleAddSession = () => {
    const newSessions = [...editedPlan.sessions];
    newSessions.push({
      id: Date.now(), // Temporary ID
      day: newSessions.length + 1,
      coaches_intro_note: 'New Session',
      approx_time: '30 minutes',
      difficulty_level: 'Beginner',
      tempo: 'Slow and Controlled',  // Add default value for tempo
      best_practice: 'Text me with any questions.',  // Add default value for best_practice
      movements: []
    });
    setEditedPlan({ ...editedPlan, sessions: newSessions });
  };

  const handleSave = async () => {
    setIsSaving(true);
    setError(null);
    try {
      await onSave(editedPlan);
      onClose();
    } catch (err) {
      setError('Failed to save the workout plan. Please try again.');
      console.error('Error saving workout plan:', err);
    } finally {
      setIsSaving(false);
    }
  };

  const moveSession = (index, direction) => {
    const newSessions = [...editedPlan.sessions];
    const newIndex = index + direction;

    if (newIndex < 0 || newIndex >= newSessions.length) return;

    const [movedSession] = newSessions.splice(index, 1);
    newSessions.splice(newIndex, 0, movedSession);

    // Update the day number for each session
    newSessions.forEach((session, idx) => {
      session.day = idx + 1;
    });

    setEditedPlan({ ...editedPlan, sessions: newSessions });
  };

  const toggleSession = (sessionId) => {
    setExpandedSessions(prev => ({
      ...prev,
      [sessionId]: !prev[sessionId]
    }));
  };

  return (
    <Rnd
      default={{
        x: 100,
        y: 100,
        width: 600,
        height: 800,
      }}
      minWidth={400}
      minHeight={400}
      bounds="window"
      className="fixed z-50 bg-white shadow-lg rounded-lg overflow-hidden"
      dragHandleClassName="drag-handle"
    >
      <div className="flex flex-col h-full">
        <div className="drag-handle flex justify-between items-center p-4 border-b cursor-move">
          <input
            className="text-xl font-bold"
            value={editedPlan.title}
            onChange={(e) => handleInputChange(e, 'title')}
          />
          <div>
            <button 
              onClick={handleSave}
              disabled={isSaving}
              className="mr-2 px-3 py-1 bg-green-500 text-white rounded hover:bg-green-600 disabled:bg-green-300"
            >
              {isSaving ? 'Saving...' : 'Save'}
            </button>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
              Close
            </button>
          </div>
        </div>
        {error && <div className="text-red-500 p-2">{error}</div>}
        <div className="flex-grow overflow-y-auto p-4">
          <textarea
            className="w-full p-2 mb-4 border rounded"
            value={editedPlan.description}
            onChange={(e) => handleInputChange(e, 'description')}
          />
          <div>
            {editedPlan.sessions.map((session, sessionIndex) => (
              <div key={session.id} className="mb-4 bg-gray-100 p-4 rounded-lg">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex flex-col mr-2">
                      <button 
                        onClick={() => moveSession(sessionIndex, -1)} 
                        disabled={sessionIndex === 0}
                        className="text-gray-500 hover:text-gray-700 disabled:text-gray-300"
                      >
                        <ArrowUp size={20} />
                      </button>
                      <button 
                        onClick={() => moveSession(sessionIndex, 1)} 
                        disabled={sessionIndex === editedPlan.sessions.length - 1}
                        className="text-gray-500 hover:text-gray-700 disabled:text-gray-300"
                      >
                        <ArrowDown size={20} />
                      </button>
                    </div>
                    <input
                      className="text-lg font-medium w-full"
                      value={`Day ${session.day}: ${session.coaches_intro_note}`}
                      onChange={(e) => handleSessionChange(sessionIndex, 'coaches_intro_note', e.target.value.split(': ')[1])}
                    />
                  </div>
                  <button onClick={() => toggleSession(session.id)}>
                    {expandedSessions[session.id] ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                  </button>
                </div>
                {expandedSessions[session.id] && (
                  <div className="mt-2">
                    <input
                      className="mb-2 w-full"
                      value={`Time: ${session.approx_time}`}
                      onChange={(e) => handleSessionChange(sessionIndex, 'approx_time', e.target.value.split(': ')[1])}
                    />
                    <input
                      className="mb-2 w-full"
                      value={`Difficulty: ${session.difficulty_level}`}
                      onChange={(e) => handleSessionChange(sessionIndex, 'difficulty_level', e.target.value.split(': ')[1])}
                    />
                    <input
                      className="mb-2 w-full"
                      placeholder="Tempo"
                      value={session.tempo || ''}
                      onChange={(e) => handleSessionChange(sessionIndex, 'tempo', e.target.value)}
                    />
                    <textarea
                      className="mb-2 w-full p-2 border rounded"
                      placeholder="Best Practice"
                      value={session.best_practice || ''}
                      onChange={(e) => handleSessionChange(sessionIndex, 'best_practice', e.target.value)}
                    />
                    <h4 className="font-medium mt-2">Movements:</h4>
                    <ul className="list-disc list-inside">
                      {session.movements.map((movement, movementIndex) => (
                        <li key={movement.id} className="mb-2 relative">
                          <div className="flex items-center justify-between">
                            <button
                              className="font-medium text-blue-600 hover:text-blue-800"
                              onClick={() => setActiveSearchDropdown(`${sessionIndex}-${movementIndex}`)}
                            >
                              {movement.title}
                            </button>
                            <button 
                              onClick={() => handleDeleteMovement(sessionIndex, movementIndex)}
                              className="text-red-500 hover:text-red-700"
                            >
                              <X size={16} />
                            </button>
                          </div>
                          {activeSearchDropdown === `${sessionIndex}-${movementIndex}` && (
                            <WorkoutSearchDropdown
                              onSelect={(workout) => handleWorkoutSelect(sessionIndex, movementIndex, workout)}
                              onClose={() => setActiveSearchDropdown(null)}
                            />
                          )}
                          <div className="ml-4 mt-1">
                            <input
                              className="w-16 mr-2 px-1 border rounded"
                              type="text"
                              value={movement.prescribed_sets}
                              onChange={(e) => handleMovementChange(sessionIndex, movementIndex, 'prescribed_sets', e.target.value)}
                            />
                            sets of
                            <input
                              className="w-16 mx-2 px-1 border rounded"
                              type="text"
                              value={movement.prescribed_reps}
                              onChange={(e) => handleMovementChange(sessionIndex, movementIndex, 'prescribed_reps', e.target.value)}
                            />
                            reps
                            <input
                              className="w-24 ml-2 px-1 border rounded"
                              type="text"
                              placeholder="Rest (optional)"
                              value={movement.prescribed_rest || ''}
                              onChange={(e) => handleMovementChange(sessionIndex, movementIndex, 'prescribed_rest', e.target.value)}
                            />
                          </div>
                        </li>
                      ))}
                    </ul>
                    <button 
                      onClick={() => handleAddMovement(sessionIndex)}
                      className="mt-2 px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
                    >
                      Add Movement
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
          <button 
            onClick={handleAddSession}
            className="mt-4 px-3 py-1 bg-purple-500 text-white rounded hover:bg-purple-600"
          >
            Add Session
          </button>
        </div>
      </div>
    </Rnd>
  );
};

export default EditWorkoutPlanPopup;