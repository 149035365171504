import { axiosInstance, csrfInstance } from './axiosConfig';

export const fetchMessages = async (clientId) => {
  try {
    const response = await axiosInstance.get(`/messages/client/${clientId}/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching messages:', error);
    throw error;
  }
};

const convertImageToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(',')[1]);
    reader.onerror = error => reject(error);
  });
};

export const sendMessage = async (clientId, text, senderId, image = null) => {
  try {
    let imageBase64 = null;
    if (image) {
      imageBase64 = await convertImageToBase64(image);
    }

    const messageData = {
      sender_id: senderId,
      text: text || '',
      image: imageBase64
    };

    const response = await csrfInstance.post(`/messages/send/${clientId}/`, messageData);
    return response.data;
  } catch (error) {
    console.error('Error sending message:', error);
    throw error;
  }
};

export const fetchWeightData = async (clientId) => {
  try {
    const response = await axiosInstance.get(`/checkins/weight/${clientId}/`);
    console.log('Weight data:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching weight data:', error);
    throw error;
  }
};

export const markAllCheckInsAsRead = async (clientId) => {
  try {
    const response = await csrfInstance.post(`/checkins/mark-as-read/${clientId}/`);
    console.log('Marked all check-ins as read:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error marking all check-ins as read:', error);
    throw error;
  }
};

export const fetchCheckIns = async (clientId) => {
  try {
    const response = await axiosInstance.get(`/checkins/client/${clientId}/`);
    console.log('Check-ins data:', response.data);  // Add this line
    return response.data;
  } catch (error) {
    console.error('Error fetching check-ins:', error);
    throw error;
  }
};

export const fetchCheckInResponses = async (clientId) => {
  try {
    const response = await axiosInstance.get(`/checkins/responses/${clientId}/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching check-in responses:', error);
    throw error;
  }
};

export const submitCheckInResponse = async (checkInId, responseText) => {
  try {
    const response = await axiosInstance.post(`/checkins/respond/${checkInId}/`, { response: responseText });
    return response.data;
  } catch (error) {
    console.error('Error submitting check-in response:', error);
    throw error;
  }
};