import React from 'react';
import { useDispatch } from 'react-redux';
import { toggleCheckInPanel, toggleMessagesPanel } from '../redux/slices/panelSlice';

const Footer = () => {
  const dispatch = useDispatch();

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-gray-100 p-2 flex justify-end">
      <div className="space-x-2">
        <button 
          className="bg-blue-500 text-white px-4 py-2 rounded"
          onClick={() => dispatch(toggleCheckInPanel())}
        >
          Check-In
        </button>
        <button 
          className="bg-green-500 text-white px-4 py-2 rounded"
          onClick={() => dispatch(toggleMessagesPanel())}
        >
          Messages
        </button>
      </div>
    </div> 
  );
};

export default Footer;