import { createSlice } from '@reduxjs/toolkit';

const panelSlice = createSlice({
  name: 'panels',
  initialState: {
    checkInPanelOpen: false,
    messagesPanelOpen: false,
    currentCheckIn: null,
    checkInResponses: [],
    messages: [],
  },
  reducers: {
    toggleCheckInPanel: (state) => {
      state.checkInPanelOpen = !state.checkInPanelOpen;
    },
    toggleMessagesPanel: (state) => {
      state.messagesPanelOpen = !state.messagesPanelOpen;
    },
    openCheckInPanel: (state) => {
      state.checkInPanelOpen = true;
    },
    closeCheckInPanel: (state) => {
      state.checkInPanelOpen = false;
    },
    openMessagesPanel: (state) => {
      state.messagesPanelOpen = true;
    },
    closeMessagesPanel: (state) => {
      state.messagesPanelOpen = false;
    },
    setCurrentCheckIn: (state, action) => {
      state.currentCheckIn = action.payload;
    },
    setCheckInResponses: (state, action) => {
      state.checkInResponses = action.payload;
    },
    setMessages: (state, action) => {
      state.messages = action.payload;
    },
  },
});

export const {
  toggleCheckInPanel,
  toggleMessagesPanel,
  openCheckInPanel,
  closeCheckInPanel,
  openMessagesPanel,
  closeMessagesPanel,
  setCurrentCheckIn,
  setCheckInResponses,
  setMessages,
} = panelSlice.actions;

export default panelSlice.reducer;