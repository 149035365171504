import React, { useState } from 'react';
import AddNewFilterModal from './AddNewFilterModal';

const Sidebar = ({ clients, onClientSelect, onFilterChange, currentFilter, customFilters, onAddFilter }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isAddFilterModalOpen, setIsAddFilterModalOpen] = useState(false);

  const filteredClients = clients.filter(client =>
    `${client.first_name || ''} ${client.last_name || ''}`.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const groupClientsByFirstLetter = () => {
    return filteredClients.reduce((acc, client) => {
      const name = (client.last_name || client.first_name || '?').trim();
      const firstLetter = name[0] ? name[0].toUpperCase() : '?';
      if (!acc[firstLetter]) {
        acc[firstLetter] = [];
      }
      acc[firstLetter].push(client);
      return acc;
    }, {});
  };

  const filters = [
    { key: 'all', label: 'All Clients' },
    { key: 'workout_plans', label: 'Needs Workout Plan' },
    { key: 'starting_soon', label: 'Starting Soon' },
    { key: 'meal_plans', label: 'Needs Meal Plan' },
    { key: 'unread_messages', label: 'Unread Messages' },
    { key: 'unread_checkins', label: 'Unread Check-ins' },
    ...customFilters.map(filter => ({
      key: `custom_${filter.id}`,
      label: filter.name,
      id: filter.id
    })),
    { key: 'add_new_filter', label: '+ Add New Filter' },
  ];

  const handleFilterChange = (value) => {
    if (value === 'add_new_filter') {
      setIsAddFilterModalOpen(true);
    } else {
      onFilterChange(value);
    }
  };

  const handleAddFilter = (newFilter) => {
    onAddFilter(newFilter);
    setIsAddFilterModalOpen(false);
  };

  const renderUnreadBadge = (client) => {
    if (client.unread_count && client.unread_count > 0) {
      return (
        <div className="ml-2 bg-red-500 text-white text-xs font-bold px-2 py-1 rounded-full">
          {client.unread_count}
        </div>
      );
    }
    return null;
  };

  return (
    <div className="h-full flex flex-col">
      <div className="p-6">
        <h2 className="text-2xl font-semibold text-gray-900">Directory</h2>
        <p className="mt-1 text-sm text-gray-600">Search directory of {clients.length} clients</p>
        <div className="mt-4 relative">
          <input
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border rounded-md"
          />
          <svg className="absolute left-3 top-3 h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
          </svg>
        </div>
        <div className="mt-4">
          <select
            value={currentFilter}
            onChange={(e) => handleFilterChange(e.target.value)}
            className="w-full px-3 py-2 border rounded-md"
          >
            {filters.map(filter => (
              <option key={filter.key} value={filter.key}>{filter.label}</option>
            ))}
          </select>
        </div>
      </div>

      <nav className="flex-1 overflow-y-auto px-6">
        {Object.entries(groupClientsByFirstLetter()).map(([letter, letterClients]) => (
          <div key={letter} className="mb-4">
            <h3 className="text-sm font-semibold text-gray-500 uppercase tracking-wider">{letter}</h3>
            <ul className="mt-2">
              {letterClients.map((client) => (
                <li key={client.id}>
                  <button
                    onClick={() => onClientSelect(client.id)}
                    className="w-full flex items-center py-3 hover:bg-gray-100"
                  >
                    <div className="h-10 w-10 rounded-full bg-gray-300 flex items-center justify-center mr-3">
                      {client.profile_image ? (
                        <img 
                          src={client.profile_image} 
                          alt={`${client.first_name || ''} ${client.last_name || ''}`} 
                          className="h-10 w-10 rounded-full object-cover"
                        />
                      ) : (
                        <span className="text-xl font-bold text-gray-600">
                          {(client.last_name && client.last_name[0]) || 
                           (client.first_name && client.first_name[0]) || 
                           '?'}
                        </span>
                      )}
                    </div>
                    <div className="text-left flex-grow">
                      <p className="text-sm font-medium text-gray-900">
                        {client.last_name && client.first_name
                          ? `${client.last_name}, ${client.first_name}`
                          : client.last_name || client.first_name || 'Unnamed Client'}
                      </p>
                      <p className="text-xs text-gray-500">{client.product || 'No product'}</p>
                    </div>
                    {renderUnreadBadge(client)}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </nav>

      <AddNewFilterModal
        isOpen={isAddFilterModalOpen}
        onClose={() => setIsAddFilterModalOpen(false)}
        onAddFilter={handleAddFilter}
      />
    </div>
  );
};

export default Sidebar;