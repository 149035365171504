import React from 'react';
import { useDispatch } from 'react-redux';
import {
  toggleCheckInPanel,
  toggleMessagesPanel,
} from '../redux/slices/panelSlice';

const Footer = () => {
  const dispatch = useDispatch();

  return (
    <div className="bg-gray-100 p-4 flex justify-end border-t border-gray-300">
      <div className="space-x-2">
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded"
          onClick={() => dispatch(toggleCheckInPanel())}
        >
          Check-In
        </button>
        <button
          className="bg-green-500 text-white px-4 py-2 rounded"
          onClick={() => dispatch(toggleMessagesPanel())}
        >
          Messages
        </button>
      </div>
    </div>
  );
};

export default Footer;
